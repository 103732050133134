/**
 * User: Niko
 * Date: 24.03.2020
 * Time: 14:20
 */
import { DEFAULT_CONFIG } from 'report-ui-components';

const devEnvironment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export const config = {
    ...DEFAULT_CONFIG,
    appId: 'de.xdot.amp.eps',
    apiPath: '/api/jsonws',
    services: {
        addReport: '/report.report/add-report',
        getReport: '/report.report/get-report.2',
    },
    mapOptions: {
        ...DEFAULT_CONFIG.mapOptions,
    },
    layers: [
        {
            id: 'eps_layer',
            title: 'EPS Meldungen',
            data: devEnvironment ? 'http://localhost:8080/o/geojson' : `${ window.location.origin }/o/geojson`,
        },
    ],
};

