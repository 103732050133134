/**
 * User: n.gorte
 * Date: 11.03.2020
 * Time: 11:26
 */

import React, { useContext, useEffect, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { deDE } from "@material-ui/core/locale";
import { useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { useGlobalContext } from "../context/GlobalContext";

if (process.env.NODE_ENV === "development") {
    import("typeface-roboto");
}

const AmpThemeContext = React.createContext({});

export const AmpThemeProvider = ({ children, themes }) => {
    const { appId } = useGlobalContext();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [darkMode, setDarkMode] = useState(prefersDarkMode);
    const localStorageKey = `${appId}.palette.dark`;

    useEffect(() => {
        const storedValue = localStorage.getItem(localStorageKey);
        if (storedValue) {
            setDarkMode(storedValue === "true");
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(localStorageKey, darkMode);
    }, [darkMode]);

    const theme = React.useMemo(() => {
        const t = darkMode ? themes.dark : themes.light;
        return createMuiTheme(
            t,
            deDE
        );
    }, [darkMode]);

    const val = React.useMemo(() => {
        return {
            darkMode,
            setDarkMode,
            isDarkMode: () => localStorage.getItem(localStorageKey) === "true",
            theme
        };
    }, [darkMode, setDarkMode, theme]);

    return (
        <AmpThemeContext.Provider value={val}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </AmpThemeContext.Provider>
    );
};

AmpThemeProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export const useAmpThemeContext = () => useContext(AmpThemeContext);
