/**
 * User: Niko
 * Date: 25.03.2020
 * Time: 11:00
 */

import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Typography } from "@material-ui/core";

export const Loader = ({
    size = 80,
    text = "Wird geladen...",
    className = "",
}) => {
    return (
        <Box
            className={className}
            display="flex"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <CircularProgress size={size} />
            <Typography>{text}</Typography>
        </Box>
    );
};

Loader.propTypes = {
    size: PropTypes.number,
    text: PropTypes.string,
    className: PropTypes.string,
};
