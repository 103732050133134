/**
 * User: Niko
 * Date: 13.03.2020
 * Time: 15:35
 */

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as device from "react-device-detect";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { mapDark } from "../assets/map-styles/dark";
import { mapLight } from "../assets/map-styles/light";

dayjs.locale("de");

const Context = React.createContext({});
Context.displayName = "AmpGlobalContext";

const devEnvironment =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";
console.info(`Dev: ${devEnvironment}`);

/*
DONT TOUCH THIS!
EXTEND OR OVERWRITE IN EACH APPLICATION USING THE "config" PROP IF NEEDED
 */
export const DEFAULT_CONFIG = {
    appId: "de.xdot.app", // set in app config
    backendUrl: devEnvironment
        ? "http://localhost:8080"
        : window.location.origin,
    geocodeUrl: `https://tiles.convotis.com/default/geocoding/v1/api?apiKey=swVCskv6Cmmj22W&lang=de`,
    reverseGeocodeUrl: `https://tiles.convotis.com/default/geocoding/v1/reverse?apiKey=swVCskv6Cmmj22W&lang=de`,
    mapOptions: {
        style: {
            default: JSON.parse(mapLight),
            dark: JSON.parse(mapDark),
        },
        maxBounds: [
            [-16.1, 35],
            [40.18, 72],
        ], //europe
        bounds: [
            [5.8663153, 47.2701114],
            [15.0419319, 55.099161],
        ], // initial bounds, germany
        center: [10.4541194, 51.1642292], // center germany
        minZoom: 4,
    },
};

export const GlobalContextProvider = ({
    children,
    config = DEFAULT_CONFIG,
}) => {
    const [state, setState] = useState({
        device,
        ...config,
    });

    const localStorageKey = `${config.appId}.firstRun`;

    useEffect(() => {
        const storedValue = localStorage.getItem(localStorageKey);
        if (storedValue && storedValue === "true") {
            localStorage.setItem(localStorageKey, false);
            setState((state) => ({
                ...state,
                firsRun: false,
            }));
        } else if (!storedValue) {
            localStorage.setItem(localStorageKey, true);
            setState((state) => ({
                ...state,
                firsRun: true,
            }));
        }
    }, []);

    return <Context.Provider value={state}>{children}</Context.Provider>;
};

GlobalContextProvider.propTypes = {
    config: PropTypes.shape({
        appId: PropTypes.string,
        apiKey: PropTypes.string,
        mapStyleUrl: PropTypes.string,
        geocodeUrl: PropTypes.string,
        reverseGeocodeUrl: PropTypes.string,
    }),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export const useGlobalContext = () => useContext(Context);
