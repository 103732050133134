import React, { Suspense } from 'react';
import { Loader } from 'report-ui-components';

const MapViewLazy = React.lazy(() => import('./views/MapView'));

function App() {
    return (
        <Suspense fallback={ <Loader/> }>
            <MapViewLazy/>
        </Suspense>
    );
}

export default App;
