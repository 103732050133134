import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalContextProvider, AmpThemeProvider,  ToastMessageContext, AMPThemes } from 'report-ui-components';
import { CssBaseline } from "@material-ui/core";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { config } from './config/config';
import './index.css';

const isDev = process.env.NODE_ENV === "development";
if(isDev) {
    document.body.style.height = "100vh"
}

ReactDOM.render(
    <GlobalContextProvider config={ config }>
        <AmpThemeProvider themes={AMPThemes}>
            <ToastMessageContext>
                {
                    isDev && <CssBaseline/>
                }
                <App/>
            </ToastMessageContext>
        </AmpThemeProvider>
    </GlobalContextProvider>, document.getElementById('amp-map-root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
