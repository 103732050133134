/**
 * User: n.gorte
 * Date: 11.03.2020
 * Time: 11:25
 */

export const AmpThemeLight = {
    palette: {
        primary: {
            main: "#1F8021",
        },
        secondary: {
            main: "#EB0000",
        },
    },
};

export const AmpThemeDark = {
    palette: {
        primary: {
            main: "#1F8021",
        },
        secondary: {
            main: "#EB0000",
        },
        background: {
            default: "#121212",
            paper: "#333",
        },
    },
};

export const themes = {
    light: AmpThemeLight,
    dark: AmpThemeDark,
};
