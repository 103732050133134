/**
 * User: Niko
 * Date: 24.03.2020
 * Time: 09:06
 */

import React, { useCallback, useContext, useState } from "react";
import { Snackbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    toast: {
        width: "100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "& > div": {
            width: "100%",
            color: "white",
        },
    },
    success: {
        "& > div": {
            backgroundColor: theme.palette.success.main,
        },
    },
    info: {
        "& > div": {
            backgroundColor: theme.palette.info.main,
        },
    },

    error: {
        "& > div": {
            backgroundColor: theme.palette.error.main,
        },
    },
}));

const ToastContext = React.createContext({});
ToastContext.displayName = "ToastMessageContext";

export const ToastMessageContext = ({ children }) => {
    const classes = useStyles();
    const [toastState, setToastState] = useState({});

    const handleClose = useCallback(
        (event, reason) => {
            setToastState({});
        },
        [setToastState]
    );

    return (
        <ToastContext.Provider value={{ toastState, setToastState }}>
            {children}
            <Snackbar
                className={`${classes.toast} ${classes[toastState.type]}`}
                open={toastState.open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={toastState.message}
                action={
                    <IconButton
                        size="small"
                        aria-label="Schließen"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                }
            ></Snackbar>
        </ToastContext.Provider>
    );
};

export const useToastMessageContext = () => {
    const { setToastState } = useContext(ToastContext);

    const showErrorToast = useCallback(
        (message) => {
            setToastState({
                open: true,
                type: "error",
                message,
            });
        },
        [setToastState]
    );

    const showSuccessToast = useCallback(
        (message) => {
            setToastState({
                open: true,
                type: "success",
                message,
            });
        },
        [setToastState]
    );

    const showInfoToast = useCallback(
        (message) => {
            setToastState({
                open: true,
                type: "info",
                message,
            });
        },
        [setToastState]
    );

    return React.useMemo(() => ({
        setToastState,
        showErrorToast,
        showSuccessToast,
        showInfoToast,
    }));
};
